import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// Config
import { API_V3_URL, API_V1_URL } from '../../config/config';

// Models
import { User } from '../../models/user.model';
import { ValidateCode } from '../../models/validatecode.models';
import { ChangePlan } from '../../models/changeplan.models';

// Alerts
import Swal from 'sweetalert2';

// RXJS
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

// Get language
import { LanguageService } from '../language/language.service';
import { SidebarService } from '../shared/sidebar.service';
import { ModalAppService } from 'src/app/components/modal-app/modal-app.service';
import { PrivateRoutes } from 'src/app/config/config.routes';
import { MixpanelService } from '../analytics/mixpanel.service';
import { GlobalStateService } from 'src/app/global-state.service';
import { ApiCallBase } from '../api-lab4u/apiCallBase';

/**
 * Component to manage all the user characteristics
 */
@Injectable()
export class UserService extends ApiCallBase {
  /**
   * The language variable is declared
   * @param language Variable to save the actual language
   */
  language: string;
  /**
   * The userId variable is declared
   * @param userId Variable to save user ID
   */
  userId: string;
  /**
   * The user variable is declared
   * @param user Variable to save the connected user data
   */
  user: User;
  /**
   * The token variable is declared
   * @param token Variable to save the token
   */
  token: string;
  /**
   * The message variable is declared
   * @param message Variable to save the message
   */
  message: string;
  /**
   * The plan_id variable is declared
   * @param plan_id Variable to save the plan_id
   */
  plan_id: string;
  /**
   * The plan_name variable is declared
   * @param plan_name Variable to save the plan_name
   */
  plan_name: string;

  /**
   * Following variables declared
   * @param languageService Service that works with the page language
   * @param http Service that allows http request
   * @param router Variable that manage the routes
   */
  constructor(
    // private socioAuthServ: AuthService,
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    private sidebarService: SidebarService,
    private modalAppService: ModalAppService,
    public mixpanelService: MixpanelService,
    public globalStateService: GlobalStateService,
  ) {
    super(languageService, http, router, globalStateService);
    this.loadStorage();
    this.language = languageService.getLanguage();
  }

  /**
   * Function that returns only if the user has sign in or not
   */
  isLogin() {
    return this.token.trim().length > 40 ? true : false;
  }

  /**
   * Function that loads the information from the localStorage
   */
  loadStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userId = localStorage.getItem('userId');
      this.language = localStorage.getItem('language');
    } else {
      this.token = '';
      this.user = null;
      this.userId = '';
    }
  }

  /**
   * Function that saves the information at the localStorage
   * @param userId Variable that brings the user ID
   * @param token Variable that brings the user token
   * @param user Variable that brings the user data
   */
  saveStorage(userId: string, token: string, user: User) {
    try {
      localStorage.setItem('userId', userId);
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('new_user_login', 'true');

      this.infoUser(this.languageService.getLanguage()).subscribe(
        (resp: any) => {
          this.user = resp.user;
          localStorage.setItem('user', JSON.stringify(this.user));
        },
      );

      this.token = token;
      this.user = user;
      this.userId = userId;
      return true;
    } catch (error) {
      return error;
    }
  }

  /**
   * Function that erases all the information (except the language) from the localStorage when signing out
   */
  logout() {
    this.user = null;
    this.token = '';
    this.userId = '';

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('App');
    localStorage.removeItem('settings');
    localStorage.removeItem('new_user_login');
    localStorage.removeItem('planningId'); // Important when logging out on planning views.

    // TODO - Check why this reset fails after token verification to fix root problem
    try {
      this.mixpanelService.reset();
    } catch (_error) {}

    this.router.navigate(['/sign-in']);
  }

  /**
   * Function that through a request post sign/in the user and return their data
   * @param user Variable that brings all the user information
   * @param rememberUser Variable to remember the users e/mail at the localStorage
   */
  login(user: User, rememberUser: boolean = false) {
    if (rememberUser) {
      localStorage.setItem('email', user.email);
    } else {
      localStorage.removeItem('email');
    }

    const url = `${API_V1_URL}/api/users/login`;

    return this.http.post(url, user, this.getHttpOptionsV1()).pipe(
      map((resp: any) => {
        user.password = '****';
        this.userId = resp.userId;
        this.saveStorage(resp.userId, resp.token, user);
        return true;
      }),
      catchError((err) => {
        if (err.status === 401) {
          if (this.language === 'es') {
            Swal.fire('Error', 'Usuario o contraseña incorrecta', 'error');
          } else {
            Swal.fire('Error', 'User or password invalid', 'error');
          }
        } else if (err.status === 404) {
          if (this.language === 'es') {
            Swal.fire({
              title: 'Error',
              text: 'Correo no registrado. ¡Créate una cuenta!',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
              customClass: {
                popup: 'custom-swal-modal',
              },
            }).then((result) => {
              if (result.value) {
                this.router.navigate(['/sign-up']);
              }
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: 'Unregistered mail. Create an account!',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
              customClass: {
                popup: 'custom-swal-modal',
              },
            }).then((result) => {
              if (result.value) {
                this.router.navigate(['/sign-up']);
              }
            });
          }
        } else {
          if (this.language === 'es') {
            Swal.fire('Error', 'Error al iniciar sesión', 'error');
          } else {
            Swal.fire('Error', 'Sign in error', 'error');
          }
        }
        return throwError(() => new Error(err.message));
      }),
    );
  }

  /**
   * Function to obtain the 100% data of the user
   * @param language Variable that brings the current language of the page
   */
  infoUser(language: string) {
    const url = `${API_V1_URL}/api/users/${this.userId}?app=LAB4physics&language=${language}`;

    return this.http.get(url, this.getHttpOptionsV1());
  }

  user_role: string;
  //* gets the user's role to use in the directive
  getRole() {
    this.user_role = this.user.user_type;
    return this.user_role;
  }
  user_deal: string;
  getInstitution() {
    this.user_deal = this.user.deal;
    return this.user_deal;
  }

  user_id: string;
  getId() {
    this.user_id = this.user.userId;
    return this.user_id;
  }
  user_email: string;
  getUserEmail() {
    this.user_email = this.user.email;
    return this.user_email.toUpperCase();
  }

  /**
   * Shows modal to select App if there isn't one already selected
   */
  showAppModal() {
    if (
      this.sidebarService.getApp() === 'undefined' ||
      this.sidebarService.getApp() === null
    ) {
      this.sidebarService.applyApp('LAB4PHYSICS');
      localStorage.setItem('App', JSON.stringify('LAB4PHYSICS'));
      setTimeout(this.setTimetoReaload, 1800);
    } else {
      this.modalAppService.hideModal();
    }
  }
  setTimetoReaload() {
    window.location.reload();
  }
  /**
   * Function to register a new user
   * @param user Variable that brings the data completed by the user
   */
  newUser(user: User) {
    const url = `${API_V1_URL}/api/users/register`;

    return this.http.post(url, user, this.getHttpOptionsV1()).pipe(
      map((resp: any) => {
        Swal.fire(
          resp.message,
          user.first_name + ' ' + user.last_name,
          'success',
        );
        return resp.userId;
      }),
      catchError((err) => {
        if (err.status === 409) {
          if (this.language === 'es') {
            Swal.fire('Error', 'El usuario ya existe', 'error');
          } else {
            Swal.fire('Error', 'User already exists', 'error');
          }
        } else {
          if (this.language === 'es') {
            Swal.fire('Error', 'Error al crear la cuenta', 'error');
          } else {
            Swal.fire('Error', 'Error creating account', 'error');
          }
        }
        return throwError(() => new Error(err.message));
      }),
    );
  }

  /**
   * Function that shows the user information at the header
   * @param language Variable that brings the current language actual of the page
   * @param application Variable that brings the actual app
   */
  showUser(language: string, application: string) {
    const url = `${API_V1_URL}/api/users/${this.userId}?app=${application}&language=${language}&token=${this.tokenV1}`;

    return this.http.get(url, this.getHttpOptionsSectionsV1()).pipe(
      map((resp: any) => {
        if (resp.user.image === 'user_no_avatar.png') {
          resp.user.image = './assets/images/no-img.png';
        }
        return resp;
      }),
    );
  }

  /**
   * Function that return the description on every section
   * @param language Variable that brings the current language actual of the page
   * @param application Variable that brings the actual app
   */
  loadDescription(language: string, application: string) {
    const url = `${API_V1_URL}/api/sections/?user_id=${this.userId}&language=${language}&device=WEB&app=${application}`;

    return this.http.get(url, this.getHttpOptionsSectionsV1());
  }

  /**
   * Function that return the experiments and tools
   * @param language Variable that brings the current language actual of the page
   * @param application Variable that brings the actual app
   */
  loadExperimentsAndTools(language: string, application: string) {
    const url = `${API_V1_URL}/api/experiments/?user_id=${this.userId}&language=${language}&device=WEB&app=${application}`;

    return this.http.get(url, this.getHttpOptionsSectionsV1()).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        switch (error.status) {
          default:
            errorMessage = `Error ${error.status}, ${error.error.message}`;
        }
        return throwError(() => new Error(errorMessage));
      }),
    );
  }

  /**
   * Function that returns the information of any category
   * @param language Variable that brings the current language actual of the page
   * @param application Variable that brings the actual app
   * @param categoryId Variable that brings the category ID to filter
   */
  loadCategories(language: string, application: string, categoryId: string) {
    const url = `${API_V1_URL}/api/categories/?user_id=${this.userId}&language=${language}&device=WEB&app=${application}&category_id=${categoryId}`;

    return this.http.get(url, this.getHttpOptionsSectionsV1());
  }

  loadCategory(language: string, application: string, categoryId: string) {
    const url = `${API_V1_URL}/api/categories/${categoryId}?user_id=${this.userId}&language=${language}&device=WEB&app=${application}`;

    return this.http.get(url, this.getHttpOptionsSectionsV1());
  }

  /**
   * Function to verify the current token of the user.
   * @param userId ID of the current User
   * @param token Current User token
   */
  verifyToken(userId: string, token: string): Observable<boolean> {
    const url = `${API_V3_URL}/auth/verify-token?user_id=${userId}&user_token=${token}`;
    return this.http.get(url, this.getHttpOptionsV3()).pipe(
      map((data: any) => data.response?.is_valid || false),
      catchError((error) => {
        console.error('Error verifying user token: ', error);
        throw error;
      }),
    );
  }

  /**
   * Function that returns the ID, among others, from the section (Does not depend on the app)
   * @param language Variable that brings the current language actual of the page
   * @param application Variable that brings the actual app
   * @param item Name of the section
   */
  sectionItem(language: string, application: string, item: string) {
    const url = `${API_V1_URL}/api/sections/items/${item}?user_id=${this.userId}&language=${language}&device=WEB&app=${application}`;

    return this.http.get(url, this.getHttpOptionsSectionsV1());
  }

  /**
   * Function that return the PDF or filtered videos by the entity and the referenceId
   *
   * NOT ALL MATERIALS ARE AVAILABLE YET ON LAB4CHEMISTRY
   * @param language Variable that brings the current language actual of the page
   * @param application Variable that brings the actual app
   * @param referenceId ID referred to the entity
   * @param entity Name of the entity from where you want to obtain the material
   */
  getMaterials(
    language: string,
    application: string,
    referenceId: string,
    entity: string,
  ) {
    const url = `${API_V1_URL}/api/materials/${entity}?user_id=${this.userId}&language=${language}&device=WEB&app=${application}&id=${referenceId}`;

    const initialResponse = this.http.get(url, this.getHttpOptionsSectionsV1());

    const materialList = initialResponse.pipe(
      tap((res: any) => {
        const materials: any[] = res.response;
        materials.forEach((mat) => {
          mat.url_download = mat.url_download.replace('http://', 'https://');
        });
      }),
    );

    return materialList;
  }

  PasswordRequestReset(user: User) {
    const url = `${API_V1_URL}'/api/users/password/request_reset`;

    return this.http.post(url, user, this.getHttpOptionsV1()).pipe(
      map((resp: any) => {
        user.password = '****';
        return resp;
      }),
      catchError((err) => {
        if (this.language === 'es') {
          Swal.fire('Error', 'Error al recuperar la contraseña', 'error');
        } else {
          Swal.fire('Error', 'Password recovery failed', 'error');
        }
        return throwError(() => new Error(err.message));
      }),
    );
  }

  /**
   * Function that through a request post sign/in the user and return their data
   * @param user Variable that brings all the user information
   * @param rememberUser Variable to remember the users e/mail at the localStorage
   */
  validateCode(codeV: ValidateCode) {
    const codeRequest: ValidateCode = new ValidateCode(
      codeV.code,
      codeV.language,
      codeV.app,
      this.userId,
    );

    const url = `${API_V1_URL}/api/plans/validate`;

    return this.http.post(url, codeRequest, this.getHttpOptionsV1()).pipe(
      map((resp: any) => {
        this.message = resp.message;
        this.plan_id = resp.plan_id;
        this.plan_name = resp.plan_name;
        return resp;
      }),
      catchError((err) => {
        if (this.language === 'es') {
          if (err.status === 416) {
            Swal.fire({
              title: '· ¡Tenemos un problema con tu cuenta!',
              text: 'Al parecer ya tienes una cuenta Premium. Para poder activar tu código escríbenos a support@lab4u.co',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
              customClass: {
                popup: 'custom-swal-modal',
              },
            }).then((result) => {
              if (result.value) {
                this.router.navigate([PrivateRoutes.HOME]);
              }
            });
          } else {
            Swal.fire(
              'Tenemos un problema con el código ingresado',
              'Para poder activar tu código escríbenos a support@lab4u.co',
              'error',
            );
          }
        } else {
          if (err.status === 416) {
            Swal.fire({
              title: 'Oops, we have a problem with your account.',
              text: 'It seems you already have a Premium account. To activate your code, write to us at support@lab4u.co ',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
              customClass: {
                popup: 'custom-swal-modal',
              },
            }).then((result) => {
              if (result.value) {
                this.router.navigate([PrivateRoutes.HOME]);
              }
            });
          } else {
            Swal.fire(
              'Oops, we have a problem with the code',
              'To activate your code, write to us at support@lab4u.co',
              'error',
            );
          }
        }

        return throwError(() => new Error(err.message));
      }),
    );
  }

  changePlan(codeV: ChangePlan) {
    const codeRequest: ChangePlan = new ChangePlan(
      codeV.language,
      codeV.app,
      this.userId,
      codeV.usergroup_name,
    );

    const url = `${API_V1_URL}/api/usergroups/add`;

    return this.http.post(url, codeRequest, this.getHttpOptionsV1()).pipe(
      map((resp: any) => {
        this.message = resp.message;
        Swal.fire('OK', resp.message, 'success');
        return true;
      }),
      catchError((err) => {
        if (err.status !== 200) {
          Swal.fire('Error', 'Plan NO Cambiado', 'error');
        } else {
          Swal.fire('Error', 'Sign in error', 'error');
        }
        return throwError(() => new Error(err.message));
      }),
    );
  }
}
