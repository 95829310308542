import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mixpanelEvent } from 'src/app/config/mixpanel-events';
import { MixpanelService } from 'src/app/services/analytics/mixpanel.service';
import { TutoriaService } from 'src/app/services/api-lab4u/tutoria/tutoria.service';
import { UserService } from 'src/app/services/user/user.service';
import { ChatMessage } from 'src/app/shared/chatbot/chatbot.component';

declare function init_plugins();

type StudyOption = {
  name: string;
  keyword: string;
  iconPath: string;
};

@Component({
  selector: 'app-tutoria',
  templateUrl: './tutoria.component.html',
  styleUrls: ['./tutoria.component.css'],
})
export class TutoriaComponent {
  userId: string = '';
  userToken: string = '';
  displayView: boolean = false;
  chatStarted: boolean = false;
  disableUserInput: boolean = false;
  selectedStudyOption: StudyOption = { name: '', keyword: '', iconPath: '' };
  studyOptions: StudyOption[] = [
    {
      name: 'Biología',
      keyword: 'BIOLOGY',
      iconPath: '../../assets/images/tutoria/biology-icon.svg',
    },
    {
      name: 'Física',
      keyword: 'PHYSICS',
      iconPath: '../../assets/images/tutoria/physics-icon.svg',
    },
    {
      name: 'Química',
      keyword: 'CHEMISTRY',
      iconPath: '../../assets/images/tutoria/chemistry-icon.svg',
    },
    // TODO Include in next iteration
    // {
    //   name: 'Matemáticas',
    //   keyword: 'MATH',
    //   iconPath: '../../assets/images/tutoria/math-icon.svg',
    // },
    {
      name: 'PAES de Ciencias',
      keyword: 'SCIENCE_PAES',
      iconPath: '../../assets/images/tutoria/science-paes-icon.svg',
    },
    {
      name: 'Física Mecánica',
      keyword: 'MECHANICAL_PHYSICS_I_INACAP',
      iconPath: '../../assets/images/tutoria/mechanical-physics-i-icon.svg',
    },
  ];
  assistantResponse: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    public mixpanelService: MixpanelService,
    private route: ActivatedRoute,
    public tutoriaService: TutoriaService,
    public userService: UserService,
  ) {}

  ngOnInit() {
    init_plugins();
    this.route.queryParams.subscribe((params) => {
      const {
        user_id: userId,
        user_email: userEmail,
        user_token: userToken,
      } = params;
      if (!(userId && userEmail && userToken)) return;
      this.displayView = true;
      this.userId = userId;
      this.userToken = userToken;
      this.mixpanelService.webviewInit(userEmail.toUpperCase());
    });
  }

  /**
   * Starts the chat with the selected option and sends the Mixpanel event.
   * @param studyOption StudyOption instance selected by the user.
   */
  selectStudyOption(studyOption: StudyOption): void {
    this.selectedStudyOption = studyOption;
    this.chatStarted = true;
    this.mixpanelService.track(mixpanelEvent.TUTORIA_CONTENT_SELECTED, {
      source_name: mixpanelEvent.SOURCE_NAME,
      selected_item: studyOption.name.toUpperCase(),
    });
    this.cdr.detectChanges();
  }

  /**
   * Sends the new chat message history to generate a new response.
   * @param chatMessageHistory array of ChatMessage instances to be sent to the endpoint.
   */
  sendChatMessageHistory(chatMessageHistory: ChatMessage[]) {
    this.disableUserInput = true;
    const body = {
      user_id: this.userId,
      content_keyword: this.selectedStudyOption.keyword,
      messages: chatMessageHistory,
    };
    this.tutoriaService
      .generateTutorIAResponse(body, this.userToken)
      .subscribe((data: any) => {
        this.assistantResponse = data.response.assistant_response;
        this.disableUserInput = false;
      });
  }

  /** Resets the chat and the selected study option */
  resetChat() {
    this.chatStarted = false;
    this.selectedStudyOption = { name: '', keyword: '', iconPath: '' };
  }
}
