<div class="chat-container">
  <div class="messages-container" #messagesContainer>
    <div
      *ngFor="let message of messages"
      class="message-container"
      [ngClass]="{ 'assistant-container': message.role === 'assistant' }"
    >
      <img
        *ngIf="avatarPath !== '' && message.role === 'assistant'"
        class="avatar"
        [ngClass]="{
          'avatar-first-appearance': message.content === '...'
        }"
        src="{{ avatarPath }}"
      />
      <div
        *ngIf="message.role === 'user' || avatarPath === ''"
        class="user-avatar"
      ></div>
      <div
        *ngIf="message.role === 'user' || message.content !== '...'"
        class="message"
        markdown
        [ngClass]="{
          user: message.role === 'user',
          assistant: message.role === 'assistant',
          'assistant-with-content':
            message.role === 'assistant' && message.content !== ''
        }"
      >
        <span>{{ message.content }}</span>
      </div>
      <div
        *ngIf="message.role === 'assistant' && message.content === '...'"
        class="assistant-typing"
      >
        <span></span><span></span><span></span>
      </div>
    </div>
  </div>
  <div
    class="input-container"
    [ngClass]="{ 'input-disabled': disableUserInput }"
  >
    <textarea
      type="text"
      [disabled]="disableUserInput"
      [placeholder]="disableUserInput ? disabledMessage : initialMessage"
      [(ngModel)]="userMessage"
      (keydown)="sendMessage($event)"
      (input)="adjustTextareaHeight($event)"
      rows="1"
    ></textarea>
    <button
      [disabled]="userMessage === '' || disableUserInput"
      (click)="sendMessage()"
    >
      <img src="../../assets/images/icon/arrow-up.svg" />
    </button>
  </div>
</div>
