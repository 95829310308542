export const mixpanelEvent = {
  /* Home */
  HOME_CLASSROOMS_SELECTED: 'HOME_CLASSROOMS_SELECTED', //*ok
  HOME_SUBJECT_SELECTED: 'HOME_SUBJECT_SELECTED', //*ok
  HOME_THEMES_SELECTED: 'HOME_THEMES_SELECTED', //*ok
  HOME_SHOW_HOME_SUCCEEDED: 'HOME_SHOW_HOME_SUCCEEDED', //*ok
  HOME_TRAINING_QUIZZES_SELECTED: 'HOME_TRAINING_QUIZZES_SELECTED', //*Ok
  HOME_PLANNING_SELECTED: 'HOME_PLANNING_SELECTED', //*ok
  HOME_RESOURCE_GENERATOR_SELECTED: 'HOME_RESOURCE_GENERATOR_SELECTED', //*ok
  /* News */
  NEWS_LINK_SELECTED: 'NEWS_LINK_SELECTED', //*Ok
  NEWS_EXPERIMENT_SELECTED: 'NEWS_EXPERIMENT_SELECTED', //*ok
  NEWS_PODCAST_SELECTED: 'NEWS_PODCAST_SELECTED', //* Done With changes
  NEWS_VIDEO_SELECTED: 'NEWS_VIDEO_SELECTED', //* Done With changes
  /* Sidebar */
  LEFT_SIDEBAR_RESOURCES_SELECTED: 'LEFT_SIDEBAR_RESOURCES_SELECTED', //*ok
  LEFT_SIDEBAR_HOME_BUTTON_SELECTED: 'LEFT_SIDEBAR_HOME_BUTTON_SELECTED', //*ok
  LEFT_SIDEBAR_LAB4UPORTAL_LOGO_SELECTED:
    'LEFT_SIDEBAR_LAB4UPORTAL_LOGO_SELECTED', //*ok
  LEFT_SIDEBAR_SUPPORT_SELECTED: 'LEFT_SIDEBAR_SUPPORT_SELECTED', //*ok
  LEFT_SIDEBAR_SIGNOUT_SELECTED: 'LEFT_SIDEBAR_SIGNOUT_SELECTED', //*ok
  /* Resources - General Docs */
  RESOURCES_DOCUMENT_PREVIEW_SELECTED: 'RESOURCES_DOCUMENT_PREVIEW_SELECTED', //! No aplica, aún
  RESOURCES_DOCUMENT_DOWNLOAD_SELECTED: 'RESOURCES_DOCUMENT_DOWNLOAD_SELECTED', //*ok
  /* //! Support - DOESN´T APPLY */
  SUPPORT_SEND_FORM_SUCCEEDED: 'SUPPORT_SEND_FORM_SUCCEEDED', //! No aplica
  /* Subject Home - Dashboard */
  SUBJECTS_GETTING_STARTED_SELECTED: 'SUBJECTS_GETTING_STARTED_SELECTED', //*ok
  SUBJECTS_TOOLS_SELECTED: 'SUBJECTS_TOOLS_SELECTED', //*ok
  SUBJECTS_EXPERIMENTS_SELECTED: 'SUBJECTS_EXPERIMENTS_SELECTED', //*ok
  SUBJECTS_TUTORIALS_SELECTED: 'SUBJECTS_TUTORIALS_SELECTED', //*ok
  /* Getting Started */
  GETTING_STARTED_HOW_WORKS_SELECTED: 'GETTING_STARTED_HOW_WORKS_SELECTED', //*ok
  GETTING_STARTED_ACTIVATION_BY_CODE_SELECTED:
    'GETTING_STARTED_ACTIVATION_BY_CODE_SELECTED', //*ok
  GETTING_STARTED_RESOURCES_SELECTED: 'GETTING_STARTED_RESOURCES_SELECTED', //*ok
  GETTING_STARTED_EXPERIMENTS_SELECTED: 'GETTING_STARTED_EXPERIMENTS_SELECTED', //*ok
  GETTING_STARTED_DOCUMENT_PREVIEW_SELECTED:
    'GETTING_STARTED_DOCUMENT_PREVIEW_SELECTED', //*ok
  GETTING_STARTED_DOCUMENT_DOWNLOAD_SELECTED:
    'GETTING_STARTED_DOCUMENT_DOWNLOAD_SELECTED', //*ok
  GETTING_STARTED_DOWNLOAD_ALL_SELECTED:
    'GETTING_STARTED_DOWNLOAD_ALL_SELECTED', //*ok
  /* Tools */
  TOOLS_TOOL_SELECTED: 'TOOLS_TOOL_SELECTED', //*ok
  TOOLS_DOWNLOAD_DOCUMENT_SELECTED: 'TOOLS_DOWNLOAD_DOCUMENT_SELECTED', //*ok
  TOOLS_PREVIEW_DOCUMENT_SELECTED: 'TOOLS_PREVIEW_DOCUMENT_SELECTED', //*ok
  TOOL_VIDEO_SELECTED: 'TOOL_VIDEO_SELECTED', //! can´t do

  /* Experiments */
  EXPERIMENTS_EXPERIMENT_CATEGORY_SELECTED:
    'EXPERIMENTS_EXPERIMENT_CATEGORY_SELECTED', //*TODO: Por arreglar
  EXPERIMENTS_EXPERIMENT_SELECTED: 'EXPERIMENTS_EXPERIMENT_SELECTED', //*ok
  EXPERIMENTS_DOWNLOAD_DOCUMENT_SELECTED:
    'EXPERIMENTS_DOWNLOAD_DOCUMENT_SELECTED', //*ok
  EXPERIMENTS_PREVIEW_DOCUMENT_SELECTED:
    'EXPERIMENTS_PREVIEW_DOCUMENT_SELECTED', //*ok
  EXPERIMENTS_EXPERIMENT_PROFILE_SUCCEEDED:
    'EXPERIMENTS_EXPERIMENT_PROFILE_SUCCEEDED',
  EXPERIMENTS_EXPERIMENT_CATEGORIES_SUCCEEDED:
    'EXPERIMENTS_EXPERIMENT_CATEGORIES_SUCCEEDED',

  /* Tutorials */
  TUTORIALS_VIDEO_SELECTED: 'TUTORIALS_VIDEO_SELECTED', //*ok

  /* Classrooms */
  CLASSROOMS_CLASSROOM_SELECTED: 'CLASSROOMS_CLASSROOM_SELECTED', //*ok
  CLASSROOMS_MANAGE_CLASSROOM_SELECTED: 'CLASSROOMS_MANAGE_CLASSROOM_SELECTED', //*ok, but no classroom id
  CLASSROOMS_CLASSROOM_CODE_SELECTED: 'CLASSROOMS_CLASSROOM_CODE_SELECTED', //*ok
  CLASSROOMS_CLASSROOM_SUBJECT_SELECTED:
    'CLASSROOMS_CLASSROOM_SUBJECT_SELECTED', //! No aplica!
  CLASSROOMS_STUDENT_ACTIVITY_SELECTED: 'CLASSROOMS_STUDENT_ACTIVITY_SELECTED', //! No aplica!
  CLASSROOMS_STUDENT_TASK_SELECTED: 'CLASSROOMS_STUDENT_TASK_SELECTED', //! No aplica!
  CLASSROOMS_CHANGE_CLASSROOM_SELECTED: 'CLASSROOMS_CHANGE_CLASSROOM_SELECTED', //! No aplica!
  //!should add the dectivate student event and change name
  /* Topbar */
  TOPBAR_CHANGE_LANGUAGE_SELECTED: 'TOPBAR_CHANGE_LANGUAGE_SELECTED', //!
  TOPBAR_CLASSROOMS_SELECTED: 'TOPBAR_CLASSROOMS_SELECTED', //! No aplica

  /* Themes */
  THEMES_THEME_SELECTED: 'THEMES_THEME_SELECTED', //* ok
  THEME_BLOG_SELECTED: 'THEME_BLOG_SELECTED', //* ok
  THEME_EXPERIMENT_SELECTED: 'THEME_EXPERIMENT_SELECTED', //* ok
  THEME_VIDEO_SELECTED: 'THEME_VIDEO_SELECTED', //* ok

  /* Training Quizzes */
  TRAINING_QUIZZES_QUIZ_RESULTS_TAB_SELECTED:
    'TRAINING_QUIZZES_QUIZ_RESULTS_TAB_SELECTED', //TODO
  TRAINING_QUIZZES_QUIZZES_LIST_TAB_SELECTED:
    'TRAINING_QUIZZES_QUIZZES_LIST_TAB_SELECTED', //TODO
  TRAINING_QUIZZES_QUIZ_SELECTED: 'TRAINING_QUIZZES_QUIZ_SELECTED', //*Ok

  /* Planning */
  UNIT_PLANNING_CREATE_UNIT_PLANNING_SUCCEEDED:
    'UNIT_PLANNING_CREATE_UNIT_PLANNING_SUCCEEDED', //*Ok
  CLASS_PLANNING_SAVE_CLASS_PLANNING_SUCCEEDED:
    'CLASS_PLANNING_SAVE_CLASS_PLANNING_SUCCEEDED', //*Ok
  ASSESSMENT_PLANNING_SAVE_ASSESSMENT_QUIZ_SUCCEEDED:
    'ASSESSMENT_PLANNING_SAVE_ASSESSMENT_QUIZ_SUCCEEDED', //*Ok
  ASSESSMENT_PLANNING_SAVE_OPEN_ENDED_QUESTION_SUCCEEDED:
    'ASSESSMENT_PLANNING_SAVE_OPEN_ENDED_QUESTION_SUCCEEDED', //*Ok
  PLANNING_LIST_CREATE_UNIT_PLANNING_SELECTED:
    'PLANNING_LIST_CREATE_UNIT_PLANNING_SELECTED', //*Ok
  PLANNING_LIST_DOWNLOAD_UNIT_PLANNING_SUCCEEDED:
    'PLANNING_LIST_DOWNLOAD_UNIT_PLANNING_SUCCEEDED', //*Ok
  PLANNING_LIST_EDIT_UNIT_PLANNING_SELECTED:
    'PLANNING_LIST_EDIT_UNIT_PLANNING_SELECTED', //*Ok
  PLANNING_LIST_UNIT_ASSESSMENTS_RESULTS_SELECTED:
    'PLANNING_LIST_UNIT_ASSESSMENTS_RESULTS_SELECTED', //*Ok
  ASSESSMENT_PLANNING_CREATE_ASSESSMENT_QUIZ_SELECTED:
    'ASSESSMENT_PLANNING_CREATE_ASSESSMENT_QUIZ_SELECTED', //*Ok
  ASSESSMENT_PLANNING_CREATE_OPEN_ENDED_QUESTION_SELECTED:
    'ASSESSMENT_PLANNING_CREATE_OPEN_ENDED_QUESTION_SELECTED', //*Ok
  ASSESSMENT_PLANNING_DOWNLOAD_OPEN_ENDED_QUESTION_SUCCEEDED:
    'ASSESSMENT_PLANNING_DOWNLOAD_OPEN_ENDED_QUESTION_SUCCEEDED', //*Ok
  ASSESSMENT_PLANNING_SHARE_ASSESSMENT_QUIZ_SELECTED:
    'ASSESSMENT_PLANNING_SHARE_ASSESSMENT_QUIZ_SELECTED', //*Ok
  CLASS_PLANNING_CREATE_ASSESSMENT_PLANNING_SELECTED:
    'CLASS_PLANNING_CREATE_ASSESSMENT_PLANNING_SELECTED', //*Ok
  CLASS_PLANNING_DELETE_ASSESSMENT_PLANNING_SUCCEEDED:
    'CLASS_PLANNING_DELETE_ASSESSMENT_PLANNING_SUCCEEDED', //*Ok
  CLASS_PLANNING_DOWNLOAD_ASSESSMENT_OPEN_ENDED_QUESTION_SUCCEEDED:
    'CLASS_PLANNING_DOWNLOAD_ASSESSMENT_OPEN_ENDED_QUESTION_SUCCEEDED', //*Ok
  CLASS_PLANNING_EDIT_ASSESSMENT_PLANNING_SELECTED:
    'CLASS_PLANNING_EDIT_ASSESSMENT_PLANNING_SELECTED', //*Ok
  CLASS_PLANNING_SHARE_ASSESSMENT_QUIZ_SELECTED:
    'CLASS_PLANNING_SHARE_ASSESSMENT_QUIZ_SELECTED', //*Ok
  UNIT_PLANNING_DOWNLOAD_UNIT_PLANNING_SUCCEEDED:
    'UNIT_PLANNING_DOWNLOAD_UNIT_PLANNING_SUCCEEDED', //*Ok
  UNIT_PLANNING_EDIT_CLASS_PLANNING_SELECTED:
    'UNIT_PLANNING_EDIT_CLASS_PLANNING_SELECTED', //*Ok

  /* Generator */
  PLANNING_LIST_CREATE_RESOURCE_PLANNING_SELECTED:
    'PLANNING_LIST_CREATE_RESOURCE_PLANNING_SELECTED', //*Ok
  PLANNING_LIST_DELETE_RESOURCE_PLANNING_SUCCEEDED:
    'PLANNING_LIST_DELETE_RESOURCE_PLANNING_SUCCEEDED', //*Ok
  PLANNING_LIST_EDIT_RESOURCE_PLANNING_SELECTED:
    'PLANNING_LIST_EDIT_RESOURCE_PLANNING_SELECTED', //*Ok
  RESOURCE_PLANNING_CREATE_RESOURCE_PLANNING_SUCCEEDED:
    'RESOURCE_PLANNING_CREATE_RESOURCE_PLANNING_SUCCEEDED', //*Ok
  RESOURCE_PLANNING_SAVE_EVALUATION_SUCCEEDED:
    'RESOURCE_PLANNING_SAVE_EVALUATION_SUCCEEDED', //*Ok
  RESOURCE_PLANNING_SAVE_LEARNING_SITUATION_SUCCEEDED:
    'RESOURCE_PLANNING_SAVE_LEARNING_SITUATION_SUCCEEDED', //*Ok
  RESOURCE_PLANNING_DELETE_EVALUATION_SUCCEEDED:
    'RESOURCE_PLANNING_DELETE_EVALUATION_SUCCEEDED', //*Ok
  RESOURCE_PLANNING_DOWNLOAD_LEARNING_SITUATION_SUCCEEDED:
    'RESOURCE_PLANNING_DOWNLOAD_LEARNING_SITUATION_SUCCEEDED', //*Ok
  RESOURCE_PLANNING_EVALUATION_CODE_SELECTED:
    'RESOURCE_PLANNING_EVALUATION_CODE_SELECTED', //*Ok
  RESOURCE_PLANNING_EVALUATION_RESULTS_SELECTED:
    'RESOURCE_PLANNING_EVALUATION_RESULTS_SELECTED', //*Ok
  RESOURCE_PLANNING_FINISH_EVALUATION_SELECTED:
    'RESOURCE_PLANNING_FINISH_EVALUATION_SELECTED', //*Ok
  RESOURCE_PLANNING_FINISH_LEARNING_SITUATION_SELECTED:
    'RESOURCE_PLANNING_FINISH_LEARNING_SITUATION_SELECTED', //*Ok

  /* TutorIA */
  TUTORIA_CONTENT_SELECTED: 'TUTORIA_CONTENT_SELECTED', //* Ok

  SOURCE_NAME: 'LAB4UPORTAL',
};

/**
 userId = this.userService.getId();
  
 //*in constructor
 public mixpanelService: MixpanelService,
 public userService: UserService,

 //*in ngOnInit
 this.mixpanelService.init(this.userId);
 
 //*in trigger
 this.mixpanelService.track(mixpanelEvent.HOME_SUBJECT_SELECTED, {
      'source_name': 'LAB4UPORTAL',
    });
 */
