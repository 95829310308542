/**
 * User’s model
 */
export class ChangePlan {
  /**
   * Variable declarations
   * @param language Variable to save user’s language
   * @param app Variable to save the origin app of the user
   * @param user_id Variable to save the user’s ID
   *  @param usergroup_name Variable to save the usergroup_name
   */
  constructor(
    public language?: string,
    public app?: string,
    public user_id?: string,
    public usergroup_name?: string,
  ) {}
}
