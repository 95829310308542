<!-- ============================================================== -->
<!-- Main wrapper  -->
<!-- ============================================================== -->
<div id="main-wrapper">
  <app-header></app-header>
  
  <div class="d-none d-md-block">
    <app-sidebar [sidebarElements]="sidebarElements"></app-sidebar>
  </div>

  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="pt-5 pt-md-1 pl-0 pl-md-5 ">

    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid pt-2 mt-4 mt-md-5 px-0 pl-md-3">

      <app-breadcrumbs></app-breadcrumbs>

      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->

      <router-outlet></router-outlet>

      <!-- ============================================================== -->
      <!-- End Page Content -->
      <!-- ============================================================== -->

      <!-- <app-footer></app-footer> -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->

  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
</div>

<!-- Modals -->
<app-modal-video></app-modal-video>
<app-modal-paywall></app-modal-paywall>
<app-modal-pdf></app-modal-pdf>
