import { Injectable } from '@angular/core';

/**
 * Service to manage language
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  /**
   * The language variable is declared
   * @param language Variable to save the language
   */
  language: string;

  /**
   * @ignore
   */
  constructor() { }

  /**
   * Function to save language at the localStorage and make a real at the end to apply changes
   * @param language Variable that bring the language
   */
  saveLanguage( language: string ) {
    // console.log(language);
    if (language.length === 2) {
      this.language = language;
      localStorage.setItem('language', JSON.stringify( this.language )  );
      return true;
    } else {
      return false;
    }
  }

  /**
   * Function that returns the current language
   */
  getLanguage() {
    if ( localStorage.getItem('language') ) {
      this.language = JSON.parse( localStorage.getItem('language') );
      return this.language;
    } else {
      return 'en';
    }
  }

  /**
   * Function that uses the new language
   * @param language Variable that brings the language to be used
   */
  applyLanguage( language: string ) {
    this.saveLanguage( language );
  }

  /**
   * Function that returns a true or false if the page has a language
   */
  existLanguage() {
    if ( localStorage.getItem('language') ) {
      return true;
    } else {
      return false;
    }
  }
}
