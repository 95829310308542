import { Injectable } from '@angular/core';
import { API_V1_URL } from '../../config/config';
import { ApiCallBase } from './apiCallBase';
import { Observable } from 'rxjs/internal/Observable';
import { LanguageService } from '../language/language.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  TeacherInfoResDTO,
  TeacherAssignClassroomDTO,
} from '../../models/user.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { classroomInfoDTO } from 'src/app/models/classrooms.model';
import { GlobalStateService } from 'src/app/global-state.service';
@Injectable({
  providedIn: 'root',
})
export class ClientSystemService extends ApiCallBase {
  constructor(
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    public globalStateService: GlobalStateService,
  ) {
    //added to make 'extend apicallbase' work properly
    super(languageService, http, router, globalStateService);
  }

  getUserId() {
    return this.userId;
  }

  getTeacherInfo(language: string): Observable<TeacherInfoResDTO> {
    let url = `${API_V1_URL}/api/clisys/user_info?user_id=${this.userId}&language=${language}`;
    return this.http.get<TeacherInfoResDTO>(url, this.getHttpOptionsV1()).pipe(
      map((response: any) => {
        return this.convertKeysToCamelCase(response.response);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          if (this.language === 'es') {
            return throwError(
              `Error ${error.status} Hubo un error desconocido`,
            );
          } else {
            return throwError(`Error ${error.status} Unknown Error`);
          }
        }
        if (error.status === 404) {
          if (this.language === 'es') {
            return throwError(
              `Error ${error.status}, El usuario no está asignado a ninguna Institución con un deal activo `,
            );
          } else {
            return throwError(
              `Error ${error.status}, The user isn’t assigned to any Institution with an active Deal`,
            );
          }
        }
        if (error.status === 422) {
          if (this.language === 'es') {
            return throwError(
              `Error ${error.status}, Failed because of missing fields`,
            );
          } else {
            return throwError(
              `Error ${error.status},  Fallo por falta de campos`,
            );
          }
        } else {
          if (this.language === 'es') {
            return throwError(
              `Error ${error.status}, Hubo un error desconocido`,
            );
          } else {
            return throwError(`Error ${error.status}, Unknown error`);
          }
        }
      }),
    );
  }

  //* gets classroom profile
  getClassroomInfo(
    language: string,
    class_id: string,
    deal_id: string,
  ): Observable<classroomInfoDTO> {
    let url = `${API_V1_URL}/api/clisys/classroom_profile?class_id=${class_id}&deal_id=${deal_id}&language=${language}`;

    return this.http.get<classroomInfoDTO>(url, this.getHttpOptionsV1()).pipe(
      map((response: any) => {
        return this.convertKeysToCamelCase(response.response);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          if (this.language === 'es') {
            return throwError(
              `Error ${error.status} Hubo un error desconocido`,
            );
          } else {
            return throwError(
              `Error ${error.status} There was an unknown error`,
            );
          }
        }
        if (error.status === 404) {
          if (this.language === 'es') {
            return throwError(`Error ${error.status}, Sala no encontrada`);
          } else {
            return throwError(`Error ${error.status}, Classroom not found`);
          }
        }
        if (error.status === 422) {
          if (this.language === 'es') {
            return throwError(
              `Error ${error.status}, Failed because of missing fields`,
            );
          } else {
            return throwError(
              `Error ${error.status},  Fallo por falta de campos`,
            );
          }
        } else {
          if (this.language === 'es') {
            return throwError(
              `Error ${error.status}, Hubo un error desconocido`,
            );
          } else {
            return throwError(`Error ${error.status}, Unknown error`);
          }
        }
      }),
    );
  }

  //*Assign user to classroom-deal
  updateTeacherInfo(
    language: string,
    dto: TeacherAssignClassroomDTO,
  ): Observable<TeacherAssignClassroomDTO> {
    let url = `${API_V1_URL}/api/clisys/assign_user?user_id=${this.userId}&language=${language}`;

    return this.http
      .post<TeacherAssignClassroomDTO>(`${url}`, dto, this.getHttpOptionsV1())
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            if (this.language === 'es') {
              return throwError(
                `Error ${error.status}, hubo un error desconocido`,
              );
            } else {
              return throwError(
                `Error ${error.status}, there was an unknown error`,
              );
            }
          }
          if (error.status === 480) {
            if (this.language === 'es') {
              return throwError(`Error ${error.status}, Deal no encontrado`);
            } else {
              return throwError(`Error ${error.status}, Deal not found`);
            }
          }
          if (error.status === 481) {
            if (this.language === 'es') {
              return throwError(`Error ${error.status}, Usuario no encontrado`);
            } else {
              return throwError(`Error ${error.status}, User not found`);
            }
          }
          if (error.status === 482) {
            if (this.language === 'es') {
              return throwError(
                `Error ${error.status}, Una o más clases no fueron encontradas`,
              );
            } else {
              return throwError(
                `Error ${error.status}, One or more Classroom were not found`,
              );
            }
          }
          if (error.status === 422) {
            if (this.language === 'es') {
              return throwError(
                `Error ${error.status}, Una o más clases no fueron encontradas`,
              );
            } else {
              return throwError(
                `Error ${error.status}, One or more Classroom were not found`,
              );
            }
          } else {
            if (this.language === 'es') {
              return throwError(
                `Error ${error.status}, Hubo un error desconocido`,
              );
            } else {
              return throwError(`Error ${error.status}, Unknown error`);
            }
          }
        }),
      );
  }

  //***** EDIT USER DATA */
  updateUserData(user_id: number, firstName: string, lastName: string) {
    const url = `${API_V1_URL}/api/users/${user_id}`;
    const body = { first_name: firstName, last_name: lastName };

    return this.http.patch(url, body, this.getHttpOptionsV1()).pipe(
      catchError((error) => {
        let errorMessage;
        if (error.status === 400) {
          errorMessage =
            this.language === 'es' ? 'Error desconocido' : 'Unknown error';
        } else if (error.status === 481) {
          errorMessage =
            this.language === 'es' ? 'Usuario no encontrado' : 'User not found';
        } else if (error.status === 422) {
          errorMessage =
            this.language === 'es'
              ? 'Faltan datos requeridos'
              : 'Missing required data';
        } else {
          errorMessage = `Error ${error.status}, ${error.error.message}`;
        }
        return throwError(errorMessage);
      }),
    );
  }

  //***** DEACTIVATE STUDENT CODE */
  deactivateStudentCode(user_id: string, code: string) {
    const url = `${API_V1_URL}/api/activation_code/undo_code_use?user_id=${user_id}&code=${code}`;
    return this.http
      .post(url, { user_id: this.userId }, this.getHttpOptionsV1())
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          switch (error.status) {
            case 400:
              console.error('Unknown error:', error);
              errorMessage =
                this.language === 'es'
                  ? `Error ${error.status}, Fallo por error desconocido`
                  : `Error ${error.status}, Failure due to unknown error`;
              break;
            case 422:
              errorMessage = `Error ${error.status}, ${error.error.message}`;
              break;
            case 480:
              console.error('User has not used that code:', error);
              errorMessage =
                this.language === 'es'
                  ? `Error ${error.status}, Fallo por usuario no ha usado ese código`
                  : `Error ${error.status}, Failure due to user not using that code`;
              break;
            default:
              errorMessage = `Error ${error.status}, ${error.error.message}`;
          }
          return throwError(errorMessage);
        }),
      );
  }
}
